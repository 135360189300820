import { createRouter, createWebHistory } from "vue-router/auto";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
});

export const updateURLPath = (path: string) => {
  const url = window.location.protocol + "//" + window.location.host + path;
  window.history.pushState({ path: url }, "", url);
};

export default router;
