import "vuetify/styles";
import { type ThemeDefinition, createVuetify } from "vuetify";
import { aliases, won } from "@/iconsets/won";
import { VBtn } from "vuetify/components/VBtn";
import { VCard } from "vuetify/components/VCard";
import { VSnackbar } from "vuetify/components/VSnackbar";

const primary70 = "#B09EFF";
const secondary25 = "rgb(79, 0, 165)";
const secondary30 = "rgb(94, 0, 193)";
const secondary50 = "#924BFF";
const secondary60 = "rgb(168, 116, 255)";
const secondary70 = "#BE98FF";
const secondaryContainer = "#7A06F6";
const tertiary25 = "#003E68";
const tertiary30 = "#00497A";
const tertiary50 = "#007BC8";
const tertiary60 = "#1D96ED";
const tertiary70 = "#5DB0FF";
const tertiaryContainer = "#0079C4";
const secondary100 = "#FFFFFF";

const wonderDarkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    // Components
    "child-lavender": "rgba(159, 80, 208)",
    microphone: "#7A008D",
    "people-btn-bg": secondary30,
    "people-btn-border": secondary25,
    "people-btn-icon-color": secondary60,
    "people-panel-unread": secondaryContainer,
    "people-subtitle": secondary70,
    "snackbar-border": secondary50,
    "snackbar-color": secondary30,
    "wonder-header": "#46389F",
    "chat-btn-unread-border": secondary100,
    "chat-btn-border": secondary30,

    // Color system
    error: "rgb(186, 26, 26)",
    error50: "#DE3730",
    "inverse-primary": "#6326FF",
    neutral80: "#C8C6C8",
    primary: "#CBBEFF",
    "on-primary": "#22055C",
    primary15: "rgb(40, 0, 124)",
    "on-primary15": primary70,
    primary20: "rgb(51, 0, 153)",
    primary40: "#6326FF",
    "on-primary40": "#F4EEFF", // primary95
    primary50: "#7C58FF",
    primary60: "#967CFF",
    primary70,
    secondary: "rgb(105, 84, 141)",
    "secondary-container": secondaryContainer,
    secondary20: "#41008A",
    secondary25,
    secondary30,
    "on-secondary30": "#ECDCFF", // secondary90
    secondary50,
    secondary60,
    secondary70,
    secondary80: "#D5BBFF",
    secondary90: "#ECDCFF",
    secondary100,
    surface: "#141318",
    "on-surface": "#E6E1E9",
    "surface-tint": "rgb(203, 190, 255)",
    tertiary: "#9BCAFF",
    "on-tertiary": "#071C33",
    "tertiary-container": tertiaryContainer,
    tertiary15: "#002745",
    "on-tertiary15": tertiary70,
    tertiary20: "#003256",
    tertiary25: "rgb(0, 62, 104)",
    tertiary30,
    tertiary40: "#0062A0",
    "on-tertiary40": "#9BCAFF", // tertiary80
    tertiary50,
    tertiary60,
    tertiary70,
    tertiary80: "#9BCAFF",
    "on-tertiary80": "#00497A", // tertiary30
    tertiary90: "#D0E4FF",
    tertiary95: "#E9F1FF",
  },
  variables: {
    toolbarBgImage: "radial-gradient(104.47% 331.15% at 37.08% 186.21%, #323da5 0%, #330b6d 77.08%, #170b32 100%)",
  },
};

const wonderGuardianTheme: ThemeDefinition = {
  dark: true,
  colors: {
    // Components
    "people-btn-bg": tertiary30,
    "people-btn-border": tertiary25,
    "people-btn-icon-color": tertiary70,
    "people-panel-unread": tertiaryContainer,
    "people-subtitle": tertiary60,
    "snackbar-border": tertiary50,
    "snackbar-color": tertiary30,

    // Color system
    "inverse-primary": "#0079C4",
    primary: "#9BCAFF", // tertiary
    "on-primary": "#071C33",
    "on-surface": "#9BCAFF", // tertiary
  },
  variables: {
    toolbarBgImage: "radial-gradient(250.87% 253.62% at 56.69% 133.89%, #004A61 0%, #003146 42.74%, #001416 100%)",
  },
};

export default createVuetify({
  // NOTE: When adding a new alias also add a new TypeScript alias at the bottom of this file.
  aliases: {
    WAuthBtn: VBtn,
    WMenuBtn: VBtn,
    WPanelButton: VBtn,
    WSnackbar: VSnackbar,
    WSpotlightCard: VCard,
  },
  defaults: {
    VToolbar: {
      height: 72,
    },
    WAuthBtn: {
      style: {
        letterSpacing: "normal",
        textTransform: "none",
      },
      rounded: "pill",
      size: "large",
    },
    WMenuBtn: {
      style: {
        letterSpacing: "normal",
        textTransform: "none",
        minHeight: "48px",
        width: "205px",
        fontFamily: "Inter, sans-serif",
        fontSize: "20px",
        fontWeight: 600,
        color: "white",
        background: "#4f00a5",
        boxShadow: "none",
      },
      rounded: "pill",
      size: "large",
    },
    WPanelButton: {
      class: "v-btn--variant-justified",
      color: "rgba(var(--v-theme-inverse-primary), 0.6)",
      size: "large",
      style: {
        borderRadius: "12px",
        color: "rgb(var(--v-theme-primary))",
      },
      variant: "flat",
    },
    WSnackbar: {
      class: "w-snackbar",
      color: "snackbar-color",
    },
    WSpotlightCard: {
      color: "tertiary50",
      rounded: "lg",
    },
  },
  icons: {
    defaultSet: "won",
    aliases,
    sets: {
      won,
    },
  },
  theme: {
    defaultTheme: "wonderDarkTheme",
    themes: {
      wonderDarkTheme,
      wonderGuardianTheme,
    },
  },
});

// Add types for Vuetify `aliases` so TypeScript tooling knows the elements exist, what their valid
// props are, etc.
declare module "@vue/runtime-core" {
  export interface GlobalComponents {
    WAuthBtn: typeof VBtn;
    WMenuBtn: typeof VBtn;
    WPanelButton: typeof VBtn;
    WSnackbar: typeof VSnackbar;
    WSpotlightCard: typeof VCard;
  }
}
